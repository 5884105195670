import React from 'react';
import {useNavigate} from "react-router-dom";
import ModelForm from "./ModelForm";
import PageSection from "../components/PageSection";
import PageTitle from "../components/Layaout/PageTitle";
import PageContainer from "../components/PageContainer";
import axios from "axios";
import {Apis} from "../util/Apis";
import {errorToast, successToast} from "../components/Layaout/ToastStyles";
import {useForm} from "react-hook-form";
import {Preview} from "../components/forms/Preview";

const ModelNew = () => {

  const navigate = useNavigate();

  const {
    formState: {errors}
  } = useForm();

  const goBack = () => {
    navigate(-1)
  }

  const create = async (params) => {
    params.comPortsNumber = parseInt(params.comPortsNumber)
    params.price = parseInt(params.price)
    params.manufacturerId = parseInt(params.manufacturerId)
    params.modelId = parseInt(params.modelId)
    params.machineTypeId = parseInt(params.machineTypeId)

    await axios.post(Apis.SALAS + '/slot-models', params)
      .then(res => {
        successToast('Modelo guardado correctamente')
        navigate('/modelo')
      })
      .catch(err => {
        errorToast('hubo un error', 6000)
      });
  }

  return (
    <PageContainer>
      <PageSection style={{padding: '20px'}}>
        <Preview text='Regresar' onClick={goBack}/>
        <PageTitle title={'Nueva modelo'}/>

        <div>
          <ModelForm value={{}} onSubmit={create} mode={false} fetchData={{}}/>
        </div>
      </PageSection>

    </PageContainer>
  );
};

export default ModelNew;
