import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import {Button, DateFilter, FlexContainer, Input, Slidebar, Table} from "@wargostec/react-components";
import PageContainerFull from "../../components/PageContainerFull";
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import {DateTime} from "luxon";
import useEditModal from "../../hooks/useEditModal";
import InvoiceNew from "./invoice/InvoiceNew";
import GenericModal2 from "../../hooks/GenericModal2";
import axios from "axios";
import {errorToast, successToast} from "../../components/Layaout/ToastStyles";
import {MdDeleteForever} from "react-icons/md";
import {Col, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useSearchParams} from "react-router-dom";

const InvoiceList = () => {

  const [searchParams, setSearchParams] = useSearchParams()
  const iniParam = searchParams.get('ini') || DateTime.local().startOf('month').toISODate()
  const finParam = searchParams.get('fin') || DateTime.local().endOf('month').toISODate()

  const [close, setClose] = useState(false);
  const [fechas, setFechas] = useState({
    ini: iniParam,
    fin: finParam,
  });

  const defaultParams = {...fechas};
  const [params, setParams] = useState(defaultParams);

  const {loading, data, fetchData} = useFetch(Apis.BILLING + '/invoices/all', [], params);

  const {register, handleSubmit, setValue, control} = useForm({
    defaultValues: defaultParams,
  });

  const {wrapper: wrapper1, refresh: refresh1, addAction} = useEditModal({
    addComponent: <InvoiceNew/>
  })

  const totalAmountValue = useCallback(props => {
    return (
      <>
        {
          props.renderValue() ? props.row.original.currency + ' ' + props.renderValue() : '---'
        }
      </>
    );
  }, []);

  const statusValue = useCallback(props => {
    return (
      <>
        {
          getStatus(props.renderValue())
        }
      </>
    );

  }, []);

  const alegraValue = useCallback(props => {
    return (
      <>
        {
          props.renderValue() ? props.renderValue() : 'Pendiente'
        }
      </>
    );
  }, []);

  const monthValue = useCallback(props => {
    return (
      <>
        {
          props.renderValue() ? DateTime.local(props.row.original.year, props.renderValue()).setLocale('es').toFormat('MMMM yyyy').toUpperCase() : '---'

        }
      </>
    );
  }, []);

  const getData = (params) => {
    setParams(params);
    setClose(!close);
    setSearchParams(params);
  };

  const handleDateChange = (newFechas) => {
    setFechas(newFechas);
    const newParams = {...params, ...newFechas};
    setParams(newParams);
    setSearchParams(newParams);
  };

  const generateInvoice = (id) => {
    return axios.post(Apis.BILLING + '/invoices/' + id + '/generate-invoice').then(() => {
      fetchData()
      successToast('Factura generada exitosamente')
    }).catch((err) => {
      errorToast(err ? err.response.data.message : 'Hubo un error al generar la factura', 2000)
    })
  }

  const deleteInvoice = (id) => {
    return axios.delete(Apis.BILLING + '/invoices/' + id).then(() => {
      fetchData()
      successToast('Factura eliminada exitosamente')
    }).catch((err) => {
      errorToast(err ? err.response.data.message : 'Hubo un error al eliminar la factura', 2000)
    })
  }

  const actionValue = useCallback(props => {
    return (
      <>
        <FlexContainer gap='8px'>
          {
            props.row.original.alegraId == null &&
            <Row>
              <Col>
                <Button text={'Generar factura'} size={'xs'} variant={'success'}
                        onClick={() => generateInvoice(props.renderValue())}/>
              </Col>
              <Col>
              <Button size={'xs'} variant={'danger'} icon={<MdDeleteForever/>}
                      onClick={() => deleteInvoice(props.renderValue())}/>
              </Col>
            </Row>
          }
        </FlexContainer>
      </>
    );
  }, []);

  const getStatus = (status) => {
    switch (status) {
      case 'CREATED':
        return 'CREADO';
      case 'PENDING':
        return 'PENDIENTE';
      case 'PAID_INCOMPLETE':
        return 'PAGADO INCOMPLETO';
      case 'PAID':
        return 'PAGADO';
      case 'OVERDUE':
        return 'VENCIDO';
      case 'VOID':
        return 'VOID';
      case 'CLOSED':
        return 'CLOSED';
      default:
        return '---';
    }
  }

  const formatFee = useCallback(props => {
    return (
      <>
        {
          props.renderValue() && parseFloat(props.renderValue()).toFixed(2)
        }
      </>
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        header: 'SalaID',
        accessorKey: 'salaId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'Nombre',
        accessorKey: 'salaName',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'ID Alegra',
        accessorKey: 'alegraId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: alegraValue,
      },
      {
        header: 'Mes',
        accessorKey: 'month',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: monthValue,
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: statusValue,
      },
      {
        header: 'Maquinas',
        accessorKey: 'machinesQuantity',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
      },
      {
        header: 'FEE',
        accessorKey: 'fee',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: formatFee,
      },
      {
        header: 'Total a pagar',
        accessorKey: 'totalAmount',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: totalAmountValue,
      },
      {
        header: 'Acciones',
        accessorKey: '_id',
        meta: {
          columnClassName: 'text-center',
        },
        cell: actionValue,
      },
    ]
    , []
  )

  useEffect(() => {
    setParams({...params, ...fechas});
  }, [fechas]);

  useEffect(() => {
    fetchData();
  }, [params]);

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Facturas'}/>
      </PageSection>

      <Slidebar
        title="Filtrar"
        toggler={() => setClose(!close)}
        isOpen={close}>
        <FlexContainer flexDirection="column" gap='16px'>
          <Input label={"Desde"} type="date" {...register("ini")} />
          <Input label={"Hasta"} type="date" {...register("fin")} />
        </FlexContainer>
        <div style={{paddingTop: '10px'}}>
          <Button variant="primary" onClick={handleSubmit(getData)} text={"BUSCAR"} />
        </div>
      </Slidebar>

      <PageSection>
        <DateFilter dates={fechas} onChange={handleDateChange} onClickCustom={() => setClose(!close)} defaultMode="monthly" monthly />
      </PageSection>

      <PageSection>
        {
          loading ?
            <ContainerLoading>
              <Loading variant='secondary'/>
            </ContainerLoading>
            :
            <Table
              searchable
              columns={columns}
              data={data}
              onFilter={() => setClose(!close)}
              pageSize={100}
              sortArray={[{id: 'salaId', desc: true}]}
              onAdd={addAction}
              />
        }
      </PageSection>
      <GenericModal2 {...wrapper1}/>
    </PageContainerFull>
  );
};

export default InvoiceList;
