import React, {useEffect, useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import PageSection from "../../components/PageSection";
import {Row} from "react-bootstrap";
import FormGroup from "../../components/FormGroup";
import Input from "../../components/forms/FormEl";
import SelectServer from "../../Selects/SelectServer";
import SelectSalas from "../../Selects/SelectSalas";
import SelectFirmware from "../../Selects/SelectFirmware";
import SwitchToggle from "../../components/Layaout/SwitchToggle";
import SelectPangolinLocation from "../../Selects/SelectPangolinLocation";
import SelectPangolinLote from "../../Selects/SelectPangolinLote";

const DevicesForm = ({value, onSubmit, setAction}) => {

  const {register, handleSubmit, watch, reset, control} = useForm();
  const [salaId,setSalaId] = useState('')

  const sala = watch('salaId')

  useEffect(() => {
    setAction(() => handleSubmit(onSubmit))
  }, [])

  useEffect(() => {
    if (value) {
      reset(value)
      setSalaId(value.salaId)
    }
  }, [value])

  return (
    <div>
      <PageSection>
        <Row>
          {/*<FormGroup>*/}
          {/*  <label>SN</label>*/}
          {/*  <Input disabled={true} {...register('sn')} />*/}
          {/*</FormGroup>*/}
          <FormGroup>
            <label>PoE</label><br/>
            <SwitchToggle
              label={'Si'}
              {...register("poe")}/>
          </FormGroup>
          <FormGroup>
            <label>Comentario</label>
            <Input type={'text'} {...register('comment')}/>
          </FormGroup>
          <FormGroup>
            <label>Sala</label>
            <Controller
              name="salaId"
              control={control}
              render={({field}) => <SelectSalas {...field} />}
            />
          </FormGroup>
          {
            sala !== '' && sala !== null &&
            <FormGroup>
              <label>Servidor</label>
              <SelectServer {...register('serverId')} salaId={sala}/>
            </FormGroup>
          }
          <FormGroup>
            <label>Firmware asignado</label>
            <Controller
              name="assignedFirmware"
              control={control}
              render={({field}) => <SelectFirmware {...field} />}
            />
          </FormGroup>
          <FormGroup>
            <label>Ubicacion</label>
            <SelectPangolinLocation {...register('locationId')} salaId={sala}/>
          </FormGroup>
          <FormGroup>
            <label>Lote</label>
            <SelectPangolinLote {...register('pangolinLoteId')} salaId={sala}/>
          </FormGroup>
          <FormGroup>
            <label>Reiniciar</label><br/>
            <SwitchToggle
              label={'Si'}
              {...register("restart")}/>
          </FormGroup>
        </Row>
      </PageSection>
    </div>
  );
};

export default DevicesForm;
