import React, {useCallback, useEffect, useMemo, useState} from 'react';
import useFetch from "../../hooks/useFetch";
import {Apis} from "../../util/Apis";
import useEditModal from "../../hooks/useEditModal";
import DevicesEdit from "./DevicesEdit";
import PageSection from "../../components/PageSection";
import PageTitle from "../../components/Layaout/PageTitle";
import {Button, Tab, Table, Tabs, Tooltip} from "@wargostec/react-components";
import {ContainerLoading} from "../SalasList";
import {Loading} from "../../components/forms/Loading";
import GenericModal2 from "../../hooks/GenericModal2";
import {Col, Row} from "react-bootstrap";
import {Controller, useForm} from "react-hook-form";
import {DateTime} from "luxon";
import PageContainerFull from "../../components/PageContainerFull";
import {FaCheck, FaClosedCaptioning, FaHammer} from "react-icons/fa";
import {GrClearOption, GrUpdate} from "react-icons/gr";
import {MdFiberManualRecord} from "react-icons/md";
import {TfiReload} from "react-icons/tfi";
import {FlexContainer} from '@wargostec/react-components/dist/components';
import {LuCopy} from "react-icons/lu";
import {errorToast, successToast} from '../../components/Layaout/ToastStyles';
import SelectSalas from "../../Selects/SelectSalas";
import RolloutNew from "./servers/RolloutNew";
import {useSearchParams} from "react-router-dom";
import {SelectV2} from "../../components/forms/FormEl";
import CommentList from "./CommentList";
import {FaLocationDot} from "react-icons/fa6";
import {ButtonBlock} from "./ProgrammingList";
import CommentNew from "./comment/CommentNew";
import {IoMdAdd} from "react-icons/io";
import {CiWarning} from "react-icons/ci";
import {FcApproval} from "react-icons/fc";
import SelectPangolinLocation from "../../Selects/SelectPangolinLocation";
import {IoAlert} from "react-icons/io5";
import {RiRestartFill} from "react-icons/ri";
import RunRestart from "./RunRestart";

const DevicesList = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const salaIdParam = searchParams.get('salaId') || '';
  const [params, setParams] = useState({salaId: salaIdParam});
  const {loading, data, fetchData} = useFetch(Apis.TGM + '/internal/pangolin', [], params);

  const {
    loading: loading2,
    data: data2,
    fetchData: fetchData2
  } = useFetch(Apis.TGM + '/pangolin-rollout', [], params, false);

  const {
    loading: loading3,
    data: data3,
    fetchData: fetchData3
  } = useFetch(Apis.TGM + '/internal/pangolin/firmware-grouped', [], params);

  const {wrapper: wrapper1, refresh: refresh1, addAction, editAction} = useEditModal({
    editComponent: <DevicesEdit/>
  })

  const {wrapper: wrapper2, refresh: refresh2, editAction: viewComment} = useEditModal({
    editComponent: <CommentList/>,
  })

  const {wrapper: wrapper3, refresh: refresh3, editAction: addComment} = useEditModal({
    editComponent: <CommentNew/>,
  })

  const {wrapper: wrapper4, refresh: refresh4, addAction: restartAll} = useEditModal({
    addComponent: <RunRestart/>
  })

  const {register, handleSubmit, getValues, control} = useForm({defaultValues: {salaId: salaIdParam}});
  const [slideOpen, setSlideOpen] = useState(false)

  const getData = (params) => {
    setParams(params)
    setSearchParams(params)
  }

  useEffect(() => {
    fetchData()
  }, [refresh1, refresh2, refresh3, refresh4])

  const actionValue = useCallback(props => {
    const sn = props.row.original.sn
    const comments = props.row.original.comments || 0
    const text = comments > 0 ? `${comments}   Comentarios` : 'Comentarios'
    return (
      <ButtonBlock>
        <Button text={'Editar'} size={'xs'} variant={'success'} onClick={() => editAction(sn)}/>
        <Button text={text} size={'xs'} variant={'warning'} onClick={() => viewComment(sn)} icon={comments > 0 ? <IoAlert color={'black'} style={{marginRight: '-10px'}}/> : '' }/>
        <Button icon={<IoMdAdd color={'black'}/>} size={'xs'} variant={'light'}
                onClick={() => addComment(sn)}/>
      </ButtonBlock>
    );
  }, []);

  const createdValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toFormat("yyyy'-'LL'-'dd' 'HH':'mm' ")}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const lastPingValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{DateTime.fromISO(props.renderValue()).toRelative()}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  useEffect(() => {
    if (params) {
      if (params.salaId) {
        fetchData2()
      }
      fetchData()
      fetchData3()
    }
  }, [params])

  const sha1Value = useCallback((props) => {
    const renderValue = props.renderValue();

    const copyToClipboard = () => {
      if (renderValue) {
        navigator.clipboard.writeText(renderValue)
          .then(() => {
            successToast('Texto copiado al portapapeles');
          })
          .catch((err) => {
            errorToast('Error al copiar el texto: ', err);
          });
      }
    };

    return (
      <FlexContainer column gap='4px' alignItems='center' justifyContent='center'>
        {renderValue ?
          <>
            <Tooltip text={renderValue} direction='top'>
              {renderValue.substring(0, 6)}
            </Tooltip>
            <Button
              size='xs'
              variant='light'
              icon={<LuCopy size={10}/>}
              style={{padding: '4px'}}
              onClick={copyToClipboard}
            />
          </>
          :
          <span>--</span>
        }

      </FlexContainer>
    );
  }, []);

  const sync = useCallback(props => <>
      {
        (props.row.original.reportedFirmwareVersion || props.row.original.assignedFirmware) &&
        <div>
          {props.row.original.otaDone ?
            <span><FaCheck color={'green'}/></span> :
            <span><GrUpdate color={'#FCB51C'}/></span>}
        </div>
      }
    </>
    , []);

  const poeField = useCallback(props => <>
      <div>
        {props.row.original.poe ?
          <span><FaCheck color={'green'}/></span> :
          <span><FaClosedCaptioning color={'gray'}/></span>}
      </div>
    </>
    , []);

  const customFirmware = useCallback(props =>
      <>
        <div>
          {props.row.original.customFirmware ?
            <Tooltip text={'Firmware flasheado directamente'} direction='top'>
              <div>
                <span><FaHammer color={'black'}/></span>
              </div>
            </Tooltip>
            :
            <span></span>
          }
        </div>
      </>
    , []);

  const locationValue = useCallback(props =>
      <>
        <div>
          {props.row.original.locationName ?
            <Tooltip text={props.row.original.locationName} direction='top'>
              <div>
                <span><FaLocationDot color={'black'}/></span>
              </div>
            </Tooltip>
            :
            <span></span>
          }
        </div>
      </>
    , []);

  const restartValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <MdFiberManualRecord color={'#fce91c'} size={28}/> :
            <MdFiberManualRecord color={'#bdb6b5'} size={28}/>}
        </div>
      }
    </>
    , []);

  const onlineValue = useCallback(props => <>
      {
        <div>
          {props.row.original.online ?
              <MdFiberManualRecord color={'#45fc1c'} size={28}/> :
              <MdFiberManualRecord color={'#f97c7c'} size={28}/>
          }
        </div>
      }
    </>
    , []);

  const statusNameValue = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span style={{display: "flex", justifyContent: "center"}}>{statusIcon(props.renderValue())}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const statusIcon = (status) => {
    switch (status) {
      case 1:
        return <div>
          <Tooltip text="Operativo">
            <FcApproval style={{color: "green"}} size={25}/>
          </Tooltip>
        </div>
      case 3:
        return <div>
          <Tooltip text="Averiado">
            <CiWarning style={{color: "red"}} size={25}/>
          </Tooltip>
        </div>
      default:
        return <div>
          <Tooltip text="Operativo">
            <FcApproval style={{color: "green"}} size={25}/>
          </Tooltip>
        </div>
    }
  }

  const columns = useMemo(
    () => [
      {
        header: 'Online',
        accessorKey: 'online',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: onlineValue
      },
      {
        header: 'SN', accessorKey: 'sn',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Estado',
        accessorKey: 'status',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: statusNameValue
      },
      {header: 'Sala', accessorKey: 'salaId'},
      {
        header: 'C',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: customFirmware
      },
      {
        header: 'SHA1',
        accessorKey: 'reportedFirmwareSha1',
        cell: sha1Value,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Servidor',
        accessorKey: 'serverId',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'Ping',
        accessorKey: 'lastPing',
        cell: lastPingValue,
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      // {
      //   header: 'Creado',
      //   accessorKey: 'createdAt',
      //   cell: createdValue, meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      // },
      // {
      //   header: 'F. Reportado',
      //   accessorKey: 'reportedFirmwareVersion',
      //   meta: {
      //     headerClassName: 'text-center',
      //     columnClassName: 'text-center',
      //   },
      // },
      {
        header: 'F. Asignado',
        accessorKey: 'assignedFirmware',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
      },
      {
        header: 'OTA',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: sync
      },
      {
        header: 'IP',
        accessorKey: 'ipAddress'
      },
      {
        header: 'PoE',
        accessorKey: 'poe',
        cell: poeField,
      },
      {
        header: 'Restart',
        accessorKey: 'restart',
        cell: restartValue,
      },
      {
        header: 'L',
        accessorKey: 'locationId',
        meta: {
          headerClassName: 'text-left',
          columnClassName: 'text-left',
        },
        cell: locationValue
      },
      {
        header: 'Acciones',
        meta: {
          headerClassName: 'text-center',
          columnClassName: 'text-center',
        },
        cell: actionValue
      },
    ]
    , []
  )

  const columnsRollOut = useMemo(
    () => [
      {
        header: 'SalaID',
        accessorKey: 'salaId',
      },
      {
        header: 'F. Asignado', accessorKey: 'assignedFirmware'
      },
      {
        header: 'Total',
        accessorKey: 'total',
      },
      {
        header: 'Procesados',
        accessorKey: 'progress',
      },
      {
        header: 'Fecha',
        accessorKey: 'createdAt',
        cell: createdValue
      },
      {
        header: 'Modificado',
        accessorKey: 'updatedAt',
        cell: createdValue
      }
    ]
    , []
  )

  const valueName = useCallback(props => <>
      {
        <div>
          {props.renderValue() ?
            <span>{props.renderValue()}</span> :
            <span>---</span>}
        </div>
      }
    </>
    , []);

  const columnsFirmware = useMemo(
    () => [
      {
        header: 'Firmware',
        accessorKey: 'firmware',
        cell: valueName
      },
      {
        header: 'SHA1',
        accessorKey: 'reportedFirmwareSha1',
        cell: valueName
      },
      {header: 'Cantidad', accessorKey: 'quantity'},
    ]
    , []
  )

  const reloadData = () => {
    fetchData()
    fetchData3()
    if (params.salaId) {
      fetchData2()
    }
  }

  const clear = () => {
    getData({})
  }

  const {wrapper: wrapper, refresh, addAction: rollout} = useEditModal({
    addComponent: <RolloutNew/>
  })

  return (
    <PageContainerFull>
      <PageSection>
        <PageTitle title={'Dispositivos'}/>
        <FlexContainer column gap='20px'>
          <Row>
            <Col>
              <div style={{width: '300px'}}>
                <Controller
                  name="salaId"
                  control={control}
                  render={({field}) => (
                    <SelectSalas
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({salaId: value});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            <Col>
              <div style={{width: '200px'}}>
                <Controller
                  name="locationId"
                  control={control}
                  render={({field}) => (
                    <SelectPangolinLocation
                      {...field}
                      onChange={(value) => {
                        field.onChange(value);
                        getData({locationId: value.target.value || ''});
                      }}
                    />
                  )}
                />
              </div>
            </Col>
            {
              data.ips && data.ips.length > 0 &&
              <Col>
                <div style={{width: '200px'}}>
                  {
                    data.ips && data.ips.length > 0 &&
                    <SelectV2
                      options={data.ips.map(ip => ({id: ip, name: ip}))}
                      label='IP'
                      onChange={(value) => {
                        getData({ip: value.target.value});
                      }}
                    />
                  }
                </div>
              </Col>
            }
            {
              data.assignedFirmware && data.assignedFirmware.length > 0 &&
              <Col>
                <div style={{width: '200px'}}>

                  <SelectV2
                    options={data.assignedFirmware.map(it => ({id: it, name: it}))}
                    label="FIRMWARE"
                    onChange={(value) => {
                      getData({assignedFirmware: value.target.value});
                    }}
                  />

                </div>
              </Col>
            }
            <Col>
              <Button style={{paddingRight: "10px"}} variant={'success'} onClick={reloadData}
                      icon={<TfiReload/>}/>
            </Col>
            <Col>
              <Button variant={'primary'} onClick={clear} icon={<GrClearOption/>}/>
            </Col>
            <Col>
              <Button variant={'warning'} text={'Reinicio Global'} onClick={restartAll} icon={<RiRestartFill />}/>
            </Col>
          </Row>

        </FlexContainer>
      </PageSection>

      <PageSection>
        <Tabs defaultActiveKey="devices" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="devices" title="Dispositivos">
            {
              loading ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <>
                  {
                    data && data.data &&
                    <Table columns={columns} data={data.data && data.data} sortArray={[{id: 'sn', desc: true}]}
                           pageSize={50}
                           searchable={true}/>
                  }
                </>
            }
          </Tab>
          <Tab eventKey="firmware" title="Firmware">
            {
              loading3 ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <Table columns={columnsFirmware} data={data3} pageSize={10} sortArray={[{id: 'quantity', desc: true}]}
                       emptyListText={'No hay información'} searchable={true}/>
            }
          </Tab>
          <Tab eventKey="rollouts" title="Rollouts">
            {
              loading2 ?
                <ContainerLoading>
                  <Loading variant='secondary'/>
                </ContainerLoading>
                :
                <Table columns={columnsRollOut} data={data2} pageSize={100} sortArray={[{id: 'createdAt', desc: true}]}
                       emptyListText={'No hay rollouts, podría cambiar o seleccionar una sala '} searchable={true}
                       onAdd={rollout}/>
            }
          </Tab>
        </Tabs>
      </PageSection>
      <GenericModal2 {...wrapper1}/>
      <GenericModal2 {...wrapper2}/>
      <GenericModal2 {...wrapper3}/>
      <GenericModal2 {...wrapper}/>
      <GenericModal2 {...wrapper4}/>
    </PageContainerFull>
  );
};

export default DevicesList;
